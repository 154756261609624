import { AgGridVue } from 'ag-grid-vue';
import { debounce, isUndefined } from 'lodash';

const FILTER_SET = 'SET';
const FILTER_TEXT = 'TEXT';

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      modelId: '',
      loading: false,
      refreshing: false,
      resetting: false,
      rowData: [],
      gridWidth: 100,
      rowHeight: 47.5,
      gridDefaults: {},
      gridOptions: {},
      gridApi: null,
      columnApi: null,
      rowSelection: 'multiple',
      floatingFilter: false,
      cacheQuickFilter: true,
      suppressExcelExport: true,
      sideBarDef: {
        toolPanels: ['columns', 'filters'],
      },
      selected: {
        nodeIds: [],
      },
      query: {
        search: '',
      },
    };
  },
  computed: {
    count() {
      if (!this.gridApi) {
        return 0;
      }

      return this.gridApi.getDisplayedRowCount();
    },
    selectedIds() {
      return this.selected.nodeIds;
    },
    selectedCount() {
      return this.selectedIds.length;
    },
  },
  watch: {
    // Whenever this is updated, trigger the grid's search
    // Throttle to every 500ms so it doesn't lag
    // eslint-disable-next-line func-names
    'query.search': debounce(function (search) {
      this.gridApi.setQuickFilter(search);
    }, 500),

    refreshing(newRefresh) {
      if (!this.gridApi) {
        return;
      }

      if (newRefresh) {
        this.gridApi.showLoadingOverlay();
        return;
      }

      this.gridApi.hideOverlay();
    },
  },
  beforeCreate() {
    this.gridOptions = {};
  },
  methods: {
    /**
     * Change the page anytime the user
     * clicks in the pagination buttons
     */
    onPageSizeChanged() {
      const { value } = document.getElementById('page-size');
      this.gridApi.paginationSetPageSize(Number(value));
    },
    /**
     * Gets called when the grid is ready
     */
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      await this.setGridColumns();
      this.postGridReady(params);
    },
    postGridReady() {},

    async setGridColumns() {
      //
    },

    /**
     * Gets the currently selected rows
     */
    getSelectedNodes() {
      const selectedNodes = [];
      this.gridApi.getSelectedNodes().forEach((node) => {
        if (node.data) {
          selectedNodes.push(node);
        }
      });

      return selectedNodes;
    },

    /**
     * Selects all rows on grid
     */
    selectAll() {
      this.gridApi.selectAll();
      this.updateRowSelected();
    },

    /**
     * Deselects all rows on grid
     */
    deselectAll() {
      this.gridApi.deselectAll();
      this.updateRowSelected();
    },

    /**
     * Gets called when the the user selects a row
     * We debounce it - If we do a select all, it will run this for *every* selected node.
     * Debounce helps us :D!
     */
    // eslint-disable-next-line func-names
    updateRowSelected: debounce(function () {
      this.selected.nodeIds = this.getSelectedNodes().map((node) => node.data[this.modelId]);
    }, 1),

    /**
     * To be overridden
     */
    preResetGridToDefaultOptions() {},

    /**
     * Resets grid to absolute default
     */
    resetGridToDefaultOptions() {
      this.preResetGridToDefaultOptions();

      this.columnApi.setPivotMode(false);
      this.columnApi.setRowGroupColumns([]);
      this.gridApi.setSortModel(this.gridDefaults.sort);
      this.gridApi.setFilterModel(this.gridDefaults.filter);
      this.gridApi.setQuickFilter(null);
      this.query.search = '';

      this.gridApi.onFilterChanged();

      this.postResetGridToDefaultOptions();
    },

    /**
     * To be overridden
     */
    postResetGridToDefaultOptions() {},

    /**
     * To be overridden
     */
    preResetGridFilters() {},

    /**
     * Resets grid filters
     */
    resetGridFilters() {
      this.preResetGridFilters();
      this.gridApi.setSortModel(this.gridDefaults.sort);
      this.gridApi.setFilterModel(this.gridDefaults.filter);
      this.gridApi.onFilterChanged();
      this.postResetGridFilters();
    },

    /**
     * To be overridden
     */
    postResetGridFilters() {},

    /**
     * Helper to set the grid filter
     */
    changeGridFilter({
      field, value, type = FILTER_SET, hide = false, toggle = false,
    }) {
      if (hide) {
        field = `hide${field}`;
      }

      this.onFilterChange({
        field,
        value,
        type,
        toggle,
      });
    },

    /**
     * Gets called whenever a filter is changed
     * Can also be used to set a filter on AG Grid
     */
    onFilterChange(filter) {
      // Do not do while resetting
      if (this.resetting) {
        return;
      }

      if (filter.type === FILTER_SET) {
        this.filterSet(filter);
      } else if (filter.type === FILTER_TEXT) {
        this.filterText(filter);
      }

      this.gridApi.onFilterChanged();
    },

    /**
     * Set AG Grid filter for set type
     */
    filterSet({ field, value, toggle }) {
      let hide = false;

      // If the field name includes 'hide' in the string
      // It means we actually want to hide the value
      if (field.includes('hide')) {
        const stringParts = field.split('hide');
        hide = true;
        // eslint-disable-next-line prefer-destructuring
        field = stringParts[1];
        field = field.charAt(0).toLowerCase() + field.substring(1);
      }

      // Get the filter instance of grid api
      const filterInstance = this.gridApi.getFilterInstance(field);

      // No value passed, select everything.
      if (isUndefined(value)) {
        filterInstance.selectEverything();
        return;
      }

      // If toggle is set, only set one value
      if (toggle) {
        filterInstance.selectNothing();
        filterInstance.selectValue(value);
        return;
      }

      // Only accepted arrays
      if (!Array.isArray(value)) {
        value = [value];
      }

      if (value.length === 0) {
        // No values, set everything
        filterInstance.selectEverything();
        filterInstance.setModel(null);
        return;
      }

      // If a value is set
      // Deselect the values
      if (hide) {
        value.forEach((v) => {
          // Empty values may be set as null on the grid so make sure we unset these too
          if (v === '') {
            filterInstance.unselectValue(null);
          }
          filterInstance.unselectValue(v);
        });
        return;
      }

      // Otherwise we have a group of values to set as the model
      filterInstance.setModel(value);
    },

    /**
     * Set AG Grid filter for text type
     */
    filterText({ field, value }) {
      const filterInstance = this.gridApi.getFilterInstance(field);

      let valueModel = null;
      if (value.length > 0) {
        valueModel = {};

        value.forEach((name, index) => {
          const condition = {
            filter: name,
            type: 'contains',
          };
          if (value.length > 1) {
            valueModel[`condition${index + 1}`] = condition;
            valueModel.operator = 'AND';
          } else {
            valueModel = condition;
          }
        });
      }

      filterInstance.setModel(valueModel);
    },

    onCellClicked() {},

    // Set the right click menu
    // https://www.ag-grid.com/javascript-grid-context-menu/
    getContextMenuItems() {
      return [
        'copy',
        'copyWithHeaders',
      ];
    },
  },
};
